import Navigation from "../Navigation/Navigation"
import ItemIndex from "../Items/ItemIndex"

const HomePage = () => {
    return (
        <>
        <Navigation/>
        <ItemIndex/>
        </>
    )
}

export default HomePage